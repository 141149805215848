import axios from "axios";
import { baseurl } from "../baseurl";

export async function orderStatusApi(data) {
  const token = localStorage.getItem("logFellerToken");
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(`${baseurl}order-payment-details`, data, {
      headers,
    });
    console.log("response from orderStatusApi", response);
    return response;
  } catch (error) {
    console.log("Error in  orderStatusApi:", error.response);
    return null;
  }
}
