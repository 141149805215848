import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { orderStatusApi } from "../Apis/MainApis";
import "../styles/Failed.css";
import { useNavigate } from "react-router-dom";

function Failed() {
  const { orderId } = useParams();
  const id = orderId?.split("=")[1];
  const navigate = useNavigate();

  useEffect(() => {
    async function orderstatus() {
      const data = {
        orderId: id,
      };
      await orderStatusApi(data);
    }
    orderstatus();
  }, [id]);

  return (
    <>
      <div
        style={{ height: "100vh" }}
        className="d-flex align-items-center justify-content-center"
      >
        <div className="failed_card">
          <div>
            <i class="fa-solid fa-xmark-large">x</i>
          </div>
          <h1>Payment Failed</h1>
          <p className="mb-2">Please try again later</p>
        </div>
      </div>
    </>
  );
}

export default Failed;
