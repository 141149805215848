import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { orderStatusApi } from "../Apis/MainApis";
import "../styles/Success.css";
import { useNavigate } from "react-router-dom";

function Success() {
  const { orderId, userId } = useParams();
  const id = orderId?.split("=")[1];
  const userid = userId?.split("=")[1];
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(10);

  useEffect(() => {
    async function orderstatus() {
      const data = {
        orderId: id,
        uid: userid,
      };
      console.log("data to be sent", data);
      await orderStatusApi(data);
    }
    orderstatus();
  }, [id, userid]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        handleApp();
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [seconds]);

  const handleApp = () => {
    window.location.href = "logfeller://home";
  };

  return (
    <div
      style={{ height: "100vh" }}
      className="d-flex align-items-center justify-content-center"
    >
      <div className="success_card">
        <div>
          <i className="checkmark">✓</i>
        </div>
        <h1>Payment Success</h1>
        <p className="mb-2">
          We will receive your purchase request
          <br />
          We will be in touch shortly
        </p>
        <p>Please wait while we redirect you to the app...</p>
        <p>Redirecting in {seconds} seconds...</p>
      </div>
    </div>
  );
}

export default Success;
