import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Checkout from "./Components/Checkout";
import Success from "./Components/Success";
import Failed from "./Components/Failed";

const router = createBrowserRouter([
  {
    path: "/:slug/:slug",
    element: <Checkout />,
  },
  {
    path: "/success/:orderId/:userId",
    element: <Success />,
  },
  {
    path: "/cancel/:orderId",
    element: <Failed />,
  },
]);

function Route() {
  return (
    <>
      <RouterProvider router={router}></RouterProvider>
    </>
  );
}

export default Route;
