import React, { useEffect } from "react";
import Iframe from "react-iframe";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Checkout() {
  const navigate=useNavigate()
  const location = useLocation();
  console.log("location only", location);
  console.log("location", location.pathname.split("/"));
  const token = location.pathname.split("/")[1];
  const url = location.pathname.split("/")[2];
  const hash = location.hash;

  console.log("token", token);
  console.log("url", url);
  console.log("hash", hash);

  const redirectUrl = () => {
    const urll = `https://checkout.stripe.com/c/pay/${url}${hash}`;
    console.log("urll", urll);
    // navigate(url)
    window.location.href = urll;
  };

  useEffect(() => {
    console.log(token,'checking')
    localStorage.setItem("logFellerToken", token);
    redirectUrl();
  }, []);

  return (
    <>
      <p>Please wait while we are redirecting you to payment gateway.....</p>
    </>
  );
}

export default Checkout;
